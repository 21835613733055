// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/YIdRkAR9m";

const enabledGestures = {Arm9wbFli: {hover: true}};

const cycleOrder = ["Arm9wbFli", "GPVNHCe9f"];

const variantClassNames = {Arm9wbFli: "framer-v-158swcd", GPVNHCe9f: "framer-v-x86qqr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Dark: "GPVNHCe9f", Default: "Arm9wbFli"}

const getProps = ({height, id, link, newTab, title, width, ...props}) => { return {...props, p5ozfsT09: title ?? props.p5ozfsT09 ?? "About", PHiPavYsJ: link ?? props.PHiPavYsJ, tfcG04EYS: newTab ?? props.tfcG04EYS, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Arm9wbFli"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, p5ozfsT09, PHiPavYsJ, tfcG04EYS, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Arm9wbFli", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ZqECH", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={PHiPavYsJ} openInNewTab={tfcG04EYS}><motion.a {...restProps} className={`${cx("framer-158swcd", className)} framer-17c1vvw`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"Arm9wbFli"} ref={ref} style={{...style}} {...addPropertyOverrides({"Arm9wbFli-hover": {"data-framer-name": undefined}, GPVNHCe9f: {"data-framer-name": "Dark"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-qyiloj"} data-styles-preset={"YIdRkAR9m"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>About</motion.p></React.Fragment>} className={"framer-mpe9sk"} data-framer-name={"Title"} layoutDependency={layoutDependency} layoutId={"w0SAF5bev"} style={{"--extracted-r6o4lv": "var(--token-57644229-9aea-4671-a3e2-529f7b58afe0, rgb(226, 229, 222))", "--framer-paragraph-spacing": "0px"}} text={p5ozfsT09} variants={{"Arm9wbFli-hover": {"--extracted-r6o4lv": "var(--token-f512b2c0-ccff-4c1c-81ef-5b1a68ff0d48, rgb(178, 238, 81)) "}, GPVNHCe9f: {"--extracted-r6o4lv": "var(--token-6d05c7e7-3db1-411c-8b7a-d2c7f93bf6a9, rgb(66, 68, 63)) "}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ZqECH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZqECH .framer-17c1vvw { display: block; }", ".framer-ZqECH .framer-158swcd { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 59px; }", ".framer-ZqECH .framer-mpe9sk { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", ".framer-ZqECH .framer-v-158swcd .framer-158swcd { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZqECH .framer-158swcd { gap: 0px; } .framer-ZqECH .framer-158swcd > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ZqECH .framer-158swcd > :first-child { margin-left: 0px; } .framer-ZqECH .framer-158swcd > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 59
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"GPVNHCe9f":{"layout":["fixed","auto"]},"DhtPPSSXU":{"layout":["fixed","auto"]}}}
 * @framerVariables {"p5ozfsT09":"title","PHiPavYsJ":"link","tfcG04EYS":"newTab"}
 */
const FrameroYo7m1vjl: React.ComponentType<Props> = withCSS(Component, css, "framer-ZqECH") as typeof Component;
export default FrameroYo7m1vjl;

FrameroYo7m1vjl.displayName = "Link";

FrameroYo7m1vjl.defaultProps = {height: 24, width: 59};

addPropertyControls(FrameroYo7m1vjl, {variant: {options: ["Arm9wbFli", "GPVNHCe9f"], optionTitles: ["Default", "Dark"], title: "Variant", type: ControlType.Enum}, p5ozfsT09: {defaultValue: "About", displayTextArea: false, title: "Title", type: ControlType.String}, PHiPavYsJ: {title: "Link", type: ControlType.Link}, tfcG04EYS: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FrameroYo7m1vjl, [...sharedStyle.fonts])